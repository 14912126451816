import React from 'react'
import Container from './main/Container'

function App() {
  return (
    <div className='App'>
      <Container/>
    </div>
  )
}

export default App
